var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-content',{attrs:{"loading":_vm.loading,"id":"baseContentTop"},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.content)?_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"font-size":"14px","font-weight":"bold","color":"purple"}},[_vm._v(" Items tagged with: ")]),_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.content[0].slug === 'fiscal-impact' ? 'Impact Analysis' : _vm.content[0].name))])])],1),(_vm.content[0].content)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"dynamic-content mb-12",domProps:{"innerHTML":_vm._s(_vm.renderToHtml(_vm.content[0].content))},on:{"click":_vm.handleClicks}})])],1):_vm._e()],1):_vm._e()]},proxy:true},{key:"content",fn:function(){return [(_vm.content)?_c('v-container',{attrs:{"id":"scrollArea","fluid":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"-50px"},attrs:{"cols":"12","sm":"12","md":"12","order-md":"1","order":"2","order-sm":"2"}},_vm._l((_vm.$store.getters.config.taggableContent),function(item,index){return _c('div',{key:index},[(_vm.content[0][item.plural])?_c('div',[(_vm.content[0][item.plural].length)?_c('div',[_c('h2',{attrs:{"id":item.slug}},[_c('div',{staticClass:"mt-8"}),(item.alias)?_c('span',{staticClass:"mt-8 catLink",class:{
                        hoverColor:
                          item.singletonPath && item.singletonPath.length
                      },on:{"click":function($event){$event.preventDefault();return _vm.routeToPath(item)}}},[_vm._v(_vm._s(_vm._f("titleCase")(item.alias)))]):_c('span',{staticClass:"mt-8",class:{
                        hoverColor:
                          item.singletonPath && item.singletonPath.length
                      },on:{"click":function($event){$event.preventDefault();return _vm.routeToPath(item)}}},[_vm._v(_vm._s(_vm._f("titleCase")(item.plural)))])]),_c(item.listComponent,{tag:"component",staticClass:"mb-5",attrs:{"items":_vm.content[0][item.plural],"rows-per-page":-1,"displayContentType":true,"contentType":item.type,"singletonPath":item.singletonPath,"appendCategory":item.appendCategory}})],1):_vm._e()]):_vm._e()])}),0)],1)],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }